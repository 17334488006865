import React from "react";
import queryString from "query-string";
import window from "global/window";
import SEO from "@src/components/SEO";
import { isEmpty, omitBy } from "lodash";
import { useRedirectHandler } from "@src/utils/useRedirectHandler";

// Universal link page, just redirects to the parent app if user does not have native app
const IndexPage = () => {
  const parsedLocation = queryString.parse(window?.location?.search || "");
  const { ch, hash } = parsedLocation;
  const commonQueryParams = new URLSearchParams(omitBy({ hash: hash?.toString() }, isEmpty));
  useRedirectHandler(() => {
    const redirectURL = `https://home.classdojo.com/#/messages/${ch || ""}?`;

    return redirectURL + commonQueryParams.toString();
  });

  return (
    <>
      <SEO noindex={true} />
    </>
  );
};
export default IndexPage;
